
.login-bg[data-v-d2f5eeec] {
    background: rgba(0, 0, 0, .3);
    background-size: cover;
    height: 100%;
}
.lockscreen-wrap[data-v-d2f5eeec] {
    position: relative;
    height: 100%;
    width: 100%;
}
.ibox-content[data-v-d2f5eeec] {
    background-color: #fff;
    /* padding: 25px 50px 30px 50px; */
    clear: both;
    border-radius: 15px;
    width: 600px;
    overflow: hidden;
    max-height: 80%;
    overflow-y: auto;
}
.ibox-content-header[data-v-d2f5eeec] {
    height: 45px;
    background-color: #E5E5E6;
    padding: 0px 15px;
}
.ibox-content-header-title[data-v-d2f5eeec] {
    font-size: 15px;
    text-align: center;
}
.ibox-content-header-close[data-v-d2f5eeec] .el-icon-close {
    font-size: 18px;
    color: #323232;
}
.ibox-content-form[data-v-d2f5eeec] {
    padding: 20px 50px 0px 10px;
}
.ibox-content-form[data-v-d2f5eeec] .el-form-item__label {
    line-height: 40px;
}
.full-width[data-v-d2f5eeec] {
    width: 100% !important;
}
.dialog-footer[data-v-d2f5eeec] {
    padding: 0px 0px 30px;
    box-sizing: border-box;
}
.dialog-footer button[data-v-d2f5eeec] {
    width: 100px;
    font-size: 14px;
}
